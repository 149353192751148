<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="addNewPickupForm"
        v-model="isFormValid"
        @submit.prevent="submitPickupForm()"
      >
        <v-row class="mt-4">
          <v-col cols="12">
            <ChooseOrderPartial
              v-if="!specificContainer || orderFetched"
              :order-data="order"
              :payment-data="payment"
              :specific-client="specificClient"
              :prepopulate="specificContainer"
              @update="updateFormValue"
            />
            <OrderWarnings :order="order" />
          </v-col>
          <v-col
            cols="12"
            class="pt-0"
          >
            <NewCoursePartial
              :course-data="course"
              :order-data="order"
              hide-phone-number
              @update="updateFormValue"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="base-hover"
        :loading="isProcessing"
        @click="submitPickupForm()"
      >
        Dodaj zabranie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import NewCoursePartial from './Partials/NewCourse'
import ChooseOrderPartial from './Partials/ChooseOrder'
import updateFormValue from '../../mixins/updateFormValue.vue'
import OrderWarnings from './OrderWarnings.vue'
import { mapState, mapActions } from 'vuex'
import { Pickup, SimplifiedOrder, Course } from '../../models'
import get from 'lodash/get'

export default {
  components: {
    NewCoursePartial,
    DialogFormWrapper,
    ChooseOrderPartial,
    OrderWarnings
  },
  mixins: [updateFormValue],
  props: {
    specificClient: {
      type: Boolean,
      default: false
    },
    specificContainer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFormValid: true,
      orderFetched: false,
      payment: {},
      ...new Pickup()
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.courses.isProcessing,
      dialogObject: state => state.layout.dialog,
      pickingOrder: state => state.order.entity,
      ordersList: state => state.orders.simplifiedList,
    }),
    dialog () {
      const { item, data: { isCourse } } = this.dialogObject
      const parsedItem = isCourse ? new Course(item) : item
      return { ...this.dialogObject, item: parsedItem }
    }
  },
  watch: {
    'order.orderId' (id) {
      if (id && !this.specificContainer) {
        const order = this.ordersList.find(order => order.id === id)
        const { driverNotes, phoneNumber, payment, container, containerType, reportedDebrisTypeId } = new SimplifiedOrder(order)
        this.order.containerTypeId = containerType
        this.order.assignedContainerTypeId = container?.containerType?.id
        this.order.reportedDebrisTypeId = reportedDebrisTypeId
        this.order.phoneNumber = phoneNumber
        this.order.driverNotes = driverNotes
        this.payment = { ...payment }
      }
    }
  },
  mounted () {
    if (this.specificContainer) {
      const id = get(this.dialog, this.dialog.data.idPath)
      this.getSingleOrder({ id })
        .then(() => {
          const { order, course: { userNotes, ...courseRest } } = new Pickup(this.pickingOrder)
          const { payment } = this.pickingOrder
          this.order = order
          this.course = courseRest
          this.payment = { ...payment }
          this.$nextTick(() => {
            this.orderFetched = true
          })
        })
    }
  },
  methods: {
    ...mapActions({
      addNewPickup (dispatch, payload) {
        const tableName = this.specificClient ? 'clientOrders' : 'courses'
        return dispatch(`${tableName}/addNewItem`, payload)
      },
      getSingleOrder: 'order/getSingleOrder',
      closeDialog: 'layout/closeDialog',
      addCourse: 'callEntry/addCourse',
    }),
    submitPickupForm () {
      if (this.$refs.addNewPickupForm.validate()) {
        const { pickupContainerTypeId: containerTypeId, ...order } = this.order
        const params = {
          ...order,
          ...this.course,
          containerTypeId,
          addressId: order.anyContainer ? order.addressId : undefined
        }
        this.addNewPickup({ params, endpoint: 'courses' })
          .then((resp) => {
            if (this.$route.query.callStart) {
              const courseIds = resp.map(course => course.id)
              this.addCourse(courseIds)
            }
            this.closeDialog()
          })
      }
    }
  }
}
</script>
